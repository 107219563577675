import { TypeApiResponse, TypeMaterial } from '../types';
import { MATERIAL, SEARCH } from './apiEndpoints';
import { api } from './api';

// Получить список всех материалов
export async function getAllMaterials(): Promise<TypeMaterial[]> {
  const response = await api.get(MATERIAL);
  return response.data;
}

// Получить данные материала по id
export async function getMaterialById(
  id: number,
): Promise<TypeMaterial | undefined> {
  const response = await api.get(`${MATERIAL}/${id}`);
  return response.data;
}

// Добавить новый материал
export async function createMaterial(
  data: TypeMaterial,
): Promise<TypeApiResponse> {
  return api.post(MATERIAL, data);
}

// Удалить материал по id
export async function deleteMaterialById(id: number): Promise<TypeApiResponse> {
  return api.delete(`${MATERIAL}/${id}`);
}

// Редактировать материал
export async function updateMaterial(
  data: TypeMaterial,
): Promise<TypeApiResponse> {
  return api.put(MATERIAL, data);
}

// Получить список всех материалов по названию и коду
export async function getAllMaterialByTitle(
  title: string,
): Promise<TypeMaterial[]> {
  const response = await api.get(`${MATERIAL + SEARCH}`, {
    params: { searchQuery: title },
  });
  return response.data;
}
