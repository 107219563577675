import { useCallback, useState } from 'react';
import * as Sentry from '@sentry/react';

type ApiFunction<T> = () => Promise<T>;

export const useDataListLoader = <T,>() => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataList, setDataList] = useState<T | undefined>();

  const getDataList = useCallback(async (apiFunction: ApiFunction<T>) => {
    setIsLoading(true);
    try {
      const response = await apiFunction();
      setDataList(response);
    } catch (error) {
      Sentry.captureException(
        new Error('Ошибка при получении данных: ' + error),
      );
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { isLoading, dataList, getDataList };
};
