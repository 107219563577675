export const API_URL = process.env.REACT_APP_API_URL;

export const OPERATION = '/operation';
export const OUTPUT = '/output';
export const PRODUCT = '/product';
export const PRODUCTS = '/products';
export const UNIT = '/unit';
export const CLIENT = '/client';
export const CLIENTS = '/clients';
export const PURCHASE = '/purchase';
export const PURCHASES = '/purchases';
export const TITLE = '/title';
export const PRODUCT_MOVEMENT = '/product-movement';
export const STOCK = '/stock';
export const STOCKS = '/stocks';
export const MOVEMENT = '/movement';
export const SHIPMENT = '/shipment';
export const PRODUCTION_TYPE = '/production-type';
export const TREE = '/tree';
export const AUTHENTICATE = '/authenticate';
export const REGISTRATION = '/registration';
export const STOCK_ADJUSTMENT = '/stock-adjustment';
export const STOCK_ADJUSTMENTS = '/stock-adjustments';
export const PAYMENT = '/payment';
export const SUBSCRIPTION = '/subscription';
export const ESTIMATED_PRICE = '/estimated-price';
export const CHECK_AUTHORIZATION = '/check-authorization';
export const STORAGE_PLACE = '/storage-place';
export const LOGOUT = '/logout';
export const RATE = '/rate';
export const LOGIN = '/login';
export const MATERIAL = '/material';
export const MATERIALS = '/materials';
export const SEARCH = '/search';
export const CATEGORY = '/category';
export const ALL = '/all';
export const SELL = '/sell';
export const ITEMS = '/items';
export const ITEM = '/item';
export const ITEM_ATTRIBUTE = '/item-attribute';
